
*, *::before, *::after {
    box-sizing: border-box;
    background: none;
    border: none;
}

.navbar-brand {
  /* Add your custom styles for the button here */
  background: none;
  border: none;
  cursor: pointer;
  
}

.navbar-brand-1 {
  /* Add your custom styles for the button here */
  background: none;
  border: none;
  cursor: pointer;
  
}

.top-right-icons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem; /* Adjust the padding as needed */
}

.card-body1{
  padding: 1rem 10rem;
  flex: 1 1 auto;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.modal-backdrop {
  background: none !important; 
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.comment-author-date {
  display: flex;
  align-items: center;
}

.comment-actions {
  display: flex;
  align-items: center;
}

.error-message {
  color: red;
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 150px;
  margin: 0 40px;
}

.logos-slide img {
  transition: transform 0.3s ease;
}

.logos-slide img:hover {
  transform: scale(1.1);
}

.logos-slide a {
  display: inline-block;
  margin: 0 20px;
  text-align: center;
  color: #FD5D14; /* Set text color */
}



.logos-slide div {
  margin-top: 10px; /* Space between image and text */
  font-size: 12px; /* Adjust font size as needed */
}


@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}


.image-container1 {
  height: 200px; /* Default height */
}

/* Media query for screens that are 1290px wide or more */
@media (min-width: 1290px) {
  .image-container1 {
    height: 400px; /* Increased height for larger screens */
  }
}

.address-suggestions {
  border: 1px solid #ccc;
  border-top: none; /* If the input has a border, you might not want double borders */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: add shadow for depth */
  position: absolute; /* To position relative to the input field */
  width: 57.55%;
  background: white;
  z-index: 1000; /* Ensure it stacks above other content */
}

.address-suggestions li {
  padding: 10px;
  list-style-type: none; /* Removes bullet points from list items */
  cursor: pointer;
}

.address-suggestions li:hover {
  background-color: #f0f0f0; /* Color change on hover */
}


.brand-name {
  margin-right: 8px;
  margin-left: 10px;
}

.slick-track {
  display: flex !important; /* Establishes this element as a flex container */
  flex-direction: row; /* Aligns children horizontally */
  justify-content: center; /* Centers children on the main-axis, which is horizontal in this case */
  align-items: center !important; /* Centers children on the cross-axis, which is vertical */
  transition: transform 6000ms linear; /* Smooth transition for the transform property */
  /* ...other properties like width, opacity, transform... */
}


/* Hypothetical class name; replace with the actual class/ID as necessary */
.sdk-payment-button {
  max-width: 480px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: false;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
}

.highlight {
  font-weight: bold; /* Make the text bold */
  color: #FD5D14; /* Change the text color to orange */
}

/* Style for the modal content */
.modal-content {
  padding: 20px;
  border-radius: 5px;
  background-color: #f4f4f4;
  color: #333;
  max-width: 500px; /* Adjust width as needed */
  margin: auto; /* Center the modal */
  overflow-wrap: break-word; /* Ensures the text wraps */
  font-family: 'Arial', sans-serif;
}

/* Style for the close button */
.close-button {
  cursor: pointer; /* Changes cursor to pointer */
  float: right; /* Position it to the right corner */
  font-size: 28px; /* Make the 'X' larger */
  font-weight: bold;
}

/* Hover effect for close button */
.close-button:hover {
  color: #d9534f; /* Bootstrap's button danger color for feedback */
}

.pagination-container {
  margin-top: 20px !important;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #FD5D14 !important;
  --rdp-background-color: #fd5d1436 !important;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-color: #fff;
  margin: 1em;
}

.error-border {
  border: 1px solid red; /* Example style for input with error */
}

/* Add to your CSS file */

.ai-svg-image {
  width: 30px;
  height: auto;
}

@media (min-width: 576px){
.col-sm-6 {
    flex: 0 0 auto;
     width: 100%; 
}
}

/* Mobile view adjustments */
@media (max-width: 480px) {
  .ai-svg-image {
      margin-right: 0; /* Remove right margin */
      margin-bottom: 10px; /* Add space below SVG */
  }
}
.visible { opacity: 1; }
.hidden { opacity: 0; }

/* styles.css (or any CSS file you use in your project) */

#contact-info {
  text-align: center;
}
#contact-info a {
  color: blue; /* Change the color as desired */
  text-decoration: underline;
}

#currency-input-group .input-group-text {
  background: transparent;
  border: none;
  border-right: none;
  position: absolute;
  z-index: 10;
  padding-left: 10px;
  color: #495057; /* Adjust the color as needed */
  pointer-events: none;
}

#currency-input {
  padding-left: 30px; /* Adjust the padding to ensure the dollar sign does not overlap the text */
}

#currency-input-group {
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
      transition: none
  }
}

.form-control[type="file"] {
  overflow: hidden
}

.form-control[type="file"]:not(:disabled):not(:read-only) {
  cursor: pointer
}

.form-control:focus {
  color: #777;
  background-color: #fff;
  border-color: #feae8a;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(253,93,20,0.25)
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,.form-control:read-only {
  background-color: #e9ecef;
  opacity: 1
}

.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end:.75rem;color: #777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width:1px;border-radius: 0;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
      transition: none
  }
}

.form-control:hover:not(:disabled):not(:read-only)::file-selector-button {
  background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end:.75rem;color: #777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width:1px;border-radius: 0;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
      transition: none
  }
}

.form-control:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button {
  background-color: #dde0e3
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #777;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-sm,.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem
}

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end:.5rem}

.form-control-sm::-webkit-file-upload-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end:.5rem}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem
}

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end:1rem}

.form-control-lg::-webkit-file-upload-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end:1rem}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: .375rem
}

.form-control-color:not(:disabled):not(:read-only) {
  cursor: pointer
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: false
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: false
}
/* General styling for the navbar container */
.navbar-container {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #343a40; /* Dark grey background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #FD5D14;
}

.bg-light-radial {
  background-image: -webkit-repeating-radial-gradient(center center, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: -o-repeating-radial-gradient(center center, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: repeating-radial-gradient(center center, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, transparent 1px, transparent 100%);
  background-size: 3px 3px;
}

.fa-x-twitter {
  font-size: 24px; /* Size of the icon */
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.highlighted {
  color: #ff0000; /* Change the color to indicate active/hover state */
  font-weight: bold; /* Optional: make it bold */
}

.navbar-brand i {
  margin-right: 0.5rem;
  color: #007bff; /* Primary color for the icon */
}

.navbar-brand-1 i {
  margin-right: 0.5rem;
  color: #007bff; /* Primary color for the icon */
}

/* Default state */
.top-page-btn {
  transition: background-color 0.3s ease, border-radius 0.3s ease;
  background-color: initial; /* Your initial background color, could be transparent */
  border-radius: 0%; /* Your initial border-radius */
  transition: transform 0.3s ease;
}

/* Styling for the logo image */
.navbar-brand img {
  margin-bottom: 0.5rem; /* equivalent to mb-2 in Tailwind */
  height: 5em !important; /* equivalent to height: '2em' */
}

/* Styling for the heading */
.navbar-brand h1 {
  margin: 0; /* equivalent to m-0 in Tailwind */
  font-size: 1.875rem; /* equivalent to text-3xl in Tailwind */
  text-transform: uppercase; /* equivalent to text-uppercase in Tailwind */
  display: flex;
  align-items: center; /* Align the icon and text */

}

.brand-logo img {
  width: 40px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}


/* Styling for the heading */
.navbar-brand-1 h1 {
  margin: 0; /* equivalent to m-0 in Tailwind */
  font-size: 1.875rem; /* equivalent to text-3xl in Tailwind */
  text-transform: uppercase; /* equivalent to text-uppercase in Tailwind */
  display: flex;
  align-items: center; /* Align the icon and text */

}

/* Add this to your CSS file */
.ql-editor {
  color: black; /* This sets the text color to black */
}

.ql-snow {
  padding-bottom:100px ;
}

@media (max-width: 540px){
  .btn-primary:disabled, .btn-primary.disabled, .btn-primary {
    margin-top: 100px;
}
}

@media (min-width: 992px){
  .btn-primary:disabled, .btn-primary.disabled, .btn-primary {
    margin-top: 70px;
  }
}

@media (max-width: 992px){
  .btn-primary:disabled, .btn-primary.disabled, .btn-primary {
    margin-top: 80px;
  }
}

@media (max-width: 464px){
  .btn-primary:disabled, .btn-primary.disabled, .btn-primary {
    margin-top: 110px;
  }
}

.dropzone {
  border: 2px dashed #FD5D14;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  color: #FD5D14;
  cursor: pointer;
  margin-bottom: 15px;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.preview {

  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: inline-block;
}

.remove-image {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
}

/* In BrowserComponent.css */
.container {
  display: flex;  
  flex-direction: column;
  align-items: center;
}

.content {
  border: 1px solid #F4F6F8;
  padding: 10px; /* Adjust padding as needed */
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  /* Override the white-space property */
  white-space: initial !important;
}

/* Styling for large screens */
@media (min-width: 992px) { /* lg breakpoint */
  .navbar  {
      padding-top: 0; /* py-lg-0 equivalent */
      padding-bottom: 0; /* py-lg-0 equivalent */
  }
}

@media (min-width: 992px) { /* lg breakpoint */
  .navbar-brand-1  {
      padding-top: 0px; /* py-lg-0 equivalent */
      padding-bottom: 0px; /* py-lg-0 equivalent */
  }
}


/* Styling for the navbar brand */
.navbar-brand h1 {
  margin: 0;
  font-size: 1.875rem; /* text-3xl */
  text-transform: uppercase;
  color: white;
}

/* Styling for the navbar brand */
.navbar-brand-1 h1 {
  margin: 0;
  font-size: 25px;
  text-transform: uppercase;
  color: white;
}

/* Styling for the navbar toggler */
.navbar-toggler {
  /* Add your custom styles for the navbar toggler here */
}

/* portfolio.css */
.image-container {
  margin: auto;
  width: 80%;
  max-height: 500px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* Styling for the navbar collapse */
.collapsing {
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.nav-item .nav-link {
  color: #ffffff; /* Sets the text color */
  padding: 0.5rem 1rem; /* Sets the top and bottom padding to 0.5rem and left and right padding to 1rem */
  text-decoration: none; /* Removes underline from links */
  transition: color 0.3s; /* Smooth transition for color change */
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus {
  color: #cccccc; /* Changes text color on hover/focus */
  text-decoration: none; /* Ensures underline doesn't appear on hover/focus */
}

/* Active link styling */
.nav-item .nav-link.active {
  color: #007bff; /* Color for the active link */
  font-weight: bold; /* Makes the active link bold */
}


/* Styling for the 'Get A Quote' button */
.nav-item.nav-link.bg-primary {
  background-color: #007bff; /* Primary background color */
  color: white; /* Text color */
  padding: 0.5rem 1rem; /* px-5 equivalent */
  margin-left: 1rem; /* ms-3 equivalent */
  display: none; /* d-none equivalent */
}

.navbar-dark .navbar-nav .nav-link {
  position: relative;
  padding: 35px 15px;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--light);
  outline: none;
  transition: .5s;
  
}

.navbar-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
}

.text-white{
  background: none;
  border: none;
}
.portfolio-header {
  max-width: '600px';
}

.portfolio-filter-button {
  width: '150px';
  height: '100px';
  background: none;
}

.portfolio-filter-overlay {
  background: 'rgba(4, 15, 40, .3)';
}
.portfolio-box::after {
  right: 0;
}

.portfolio-box::before, .portfolio-box::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  transition: .5s;
  z-index: 1;
  background: rgba(4, 15, 40, .7);
}

.icon-no-background {
  background: none !important;
  border: none;
}

/* In BrowserComponent.css */
/* In BrowserComponent.css */
.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.icon {
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.icon:hover {
  color: #007bff; /* Change color on hover */
}

.makeStyles-container-1{
  background: #F4F6F8 !important;
}

.makeStyles-iconContainer-3{
  display: flex;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

[class^="bi-"]::before, [class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none;
}

@media (max-width: 540px) {
  .brand-title {
    display: flex; /* Align children horizontally */
    align-items: center; /* Center children vertically */
    gap: 1px; /* Optional: Add some space between the logo and the text */
  }
}  

@media (max-width: 540px){
  .brand-logo img {
    width: 20px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }  
}

@media (max-width: 540px) {
  .navbar-brand h1, .navbar-brand-1 h1 {
    flex-direction: column;
    font-size: 15px;
    flex-direction: row;
  }
}

.navbar-brand-1 h1 {
  display: flex;
  align-items: center; /* Align the icon and text */
}


@media (min-width: 992px) { /* lg breakpoint */
  .nav-item.nav-link.bg-primary {
      display: block; /* d-lg-block equivalent */
  }
}

@media (max-width: 991.98px){
.navbar-dark .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
 }
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #FD5D14;
  background: none;
  border: none;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out;
}

.footer-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.left-images, .right-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-image {
  margin: 5px;
  height: 100px;
}

/* Adjusted styles for individual images */
.owen-image { height: 120px; } /* Adjusted width */
.b-image {height: 155px; } /* Adjusted width */
.ncra-image {height: 120px;}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .footer-images {
    flex-direction: column;
    align-items: center;
  }

  .left-images, .right-images {
    width: 100%;
    justify-content: center;
  }
}